<template>
  <div>
    <el-dialog
      :title="title"
      :visible="dialogVisible"
      width="900px"
      :before-close="close"
      @open="open"
    >
      <Divider :title="'基础信息'" style="margin: 10px 0"></Divider>
      <el-row>
        <el-col :span="11">
          付款账户：{{ loanDetail.payAccountName }}-{{
            loanDetail.payAccount
          }}
        </el-col>
        <el-col :span="11" :push="1">
          收款账户：{{ loanDetail.payeeAccountName }}-{{
            loanDetail.payeeAccount
          }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">{{ title.substring(0, 2) }}金额：￥{{ loanDetail.money | money }}</el-col>
        <el-col :span="11" :push="1">发起时间：{{ loanDetail.createTime | dateVal }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="11">备注：{{ loanDetail.remark }}</el-col>
      </el-row>
      <Divider :title="'审批记录'" style="margin: 10px 0">
        <span slot="right" style="color: grey">
          <span v-if="detail.state === '-1'">待审核</span>
          <span v-if="detail.state === '0'">未通过</span>
          <span v-if="detail.state === '1'">通过</span>
          <span v-if="detail.state === '2'">通过</span>
          <span v-if="detail.state === '3'">作废</span>
        </span>
      </Divider>
      <el-table :data="List" border style="width: 100%">
        <el-table-column label="审批人">
          <template slot-scope="scope">
            <span>{{ scope.row.checkerName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkerPhone" label="手机号">
          <template slot-scope="scope">
            <span>{{ scope.row.checkerPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state === '-1'" style="color: blue">待审核</span>
            <span v-if="scope.row.state === '0'" style="color: red">未通过</span>
            <span v-if="scope.row.state === '1'" style="color: blue">通过</span>
            <span v-if="scope.row.state === '2'" style="color: blue">作废</span>
          </template>
        </el-table-column>
        <el-table-column prop="checkTime" label="时间">
          <template slot-scope="scope">
            <span v-if="scope.row.checkTime">{{ scope.row.checkTime | dateVal }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const projectCheckInfo = api()('project.check.info.json');
export default {
  data () {
    return {
      List: [],
      detail: {}
    }
  },
  props: {
    code: String,
    dialogVisible: Boolean,
    title: String,
    loanDetail: Object,
    state: String

  },
  components: {
    Divider: () => import('../../components/divider.vue'),
  },


  methods: {

    async open () {
      console.log(this.loanDetail, 11111);
      const params = {
        code: this.code,
      };
      await projectCheckInfo(params).then((res) => {
        console.log(res, 111);
        this.detail = res
        if (this.title === '借款审批') {
          this.List = res.list.filter((it) => it.type === 'borrowViewers');
        }
        else if (this.title === '还款审批') {
          this.List = res.list.filter((it) => it.type === 'payBackViewers');
        }
        else {
          this.List = res.list.filter((it) => it.type === 'carryOverViewers');
        }

      });
    },
    close () {
      this.$emit('update:dialogVisible', false);
      this.$emit('close');
    },
  },

}
</script>